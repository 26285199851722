enum FileAttachmentType {
    None = 0,
    GenericAttachment = 1,
    ImageGalleryItem = 2,
    Logo = 3,

    // Website stuff
    Background = 100,
    FeatureRow = 101,
    AdditionalInfo = 102,
    License = 103
}

export default FileAttachmentType
