
import { BlogModel, BlogPostStatus, UpdateBlogModel } from 'tradingmate-modules/src/models/api/blogs'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'

import { FormPage } from '@/mixins'
import ImageGallery from '@/components/inputs/ImageGallery.vue'
import Copyable from '@/components/inputs/Copyable.vue'
import Modal from '@/components/actions/Modal.vue'
import { InputDateTime, InputText, InputTextarea } from '@/components/inputs'
import { FileAttachmentType } from 'tradingmate-modules/src/models/api/files'
import AttachedObjectType from 'tradingmate-modules/src/models/api/files/AttachedObjectType'
import { Config } from 'tradingmate-modules/src/environment'
import ContentHtmlInput from '@/components/inputs/ContentHtmlInput.vue'

@Component({
  components: {
    ContentHtmlInput,
    ImageGallery,
    Copyable,
    Modal,
    InputText,
    InputDateTime,
    InputTextarea
  }
})
export default class BlogEdit extends Mixins(FormPage) {
  private blog: BlogModel | null = null
  private updateBlogModel: UpdateBlogModel | null = null
  private savedSuccessfully = false;
  private saveUnseccessful = false

  private deleteModalActive = false;
  private pulishModalActive = false;
  private unpulishModalActive = false;

  private attachementType = FileAttachmentType.ImageGalleryItem
  private objectType = AttachedObjectType.Blog
  private publicUrl = Config.WebsiteUrls.Public

  mounted (): void {
    this.getBlog()
  }

  getBlog (): void {
    const slug = this.$route.params.slug
    if (!slug) {
      this.$router.push('/blogs')
      return
    }
    Services.API.Blogs.GetBlogForPrivate(slug)
      .then((blog) => {
        this.blog = blog
        this.blogModelToUpdateBlogModel()
      }).catch(() => {
        // this.$router.push('/blogs')
      })
  }

  blogModelToUpdateBlogModel (): void {
    if (this.blog === null) return
    this.updateBlogModel = {
      Heading: this.blog.Heading,
      Subheading: this.blog.Subheading,
      Status: this.blog.Status,
      ExternalLink1: this.blog.ExternalLinks[0],
      ExternalLink2: this.blog.ExternalLinks[1],
      Content: this.blog.Content,
      ExpirationDate: this.blog.ExpirationDate
    }
  }

  savePost (): void {
    if (this.blog === null) return
    if (this.updateBlogModel === null) return

    this.httpError = null
    this.savedSuccessfully = false
    this.saveUnseccessful = false
    this.loading = true

    console.log(this.updateBlogModel)

    Services.API.Blogs.UpdateBlog(this.blog.BlogId, this.updateBlogModel)
      .then((returnBlog) => {
        this.blog = returnBlog
        this.blogModelToUpdateBlogModel()
        this.savedSuccessfully = true
      }).catch((error) => {
        this.saveUnseccessful = true
        this.httpError = error
      }).finally(() => {
        this.loading = false
        this.scrollToTop()
      })
  }

  publishPost (): void {
    this.pulishModalActive = false
    if (this.updateBlogModel === null) return
    this.updateBlogModel.Status = BlogPostStatus.Published
    this.savePost()
  }

  unpublishPost (): void {
    this.unpulishModalActive = false
    if (this.updateBlogModel === null) return
    this.updateBlogModel.Status = BlogPostStatus.Draft
    this.savePost()
  }

  deletePost (): void {
    if (this.blog === null) return

    Services.API.Blogs.DeleteBlog(this.blog.BlogId)
      .catch((error) => {
        this.httpError = error
      })
    this.$router.push({ path: '/blogs' })
  }

  scrollToTop (): void {
    const scrollPoint = this.$refs.scrollPoint as HTMLElement
    scrollPoint.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
}
